import PropTypes from 'prop-types';
import React from 'react';

import Navbar from './navbar';

const Header = ({ siteTitle, pageInfo }) => (
  <header>
    <Navbar siteTitle={siteTitle} pageInfo={pageInfo} />
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  pageInfo: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
  pageInfo: ``,
};

export default Header;
