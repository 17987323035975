import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Navbar, Nav, Form, FormControl, Button } from 'react-bootstrap';

const CustomNavbar = ({ siteTitle, pageInfo }) => {
  return (
    <>
      <Navbar variant="dark" expand="lg" id="site-navbar">
        {/* <Container> */}
        <Link to="/" className="link-no-style">
          <Navbar.Brand as="span">{siteTitle}</Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
            <Link to="/page-2" className="link-no-style">
              <Nav.Link as="span" eventKey="page-2">
                Page 2
              </Nav.Link>
            </Link>
          </Nav>
          <Nav className="ml-auto">
            <Form inline onSubmit={(e) => e.preventDefault()}>
              <Form.Group>
                <FormControl
                  type="text"
                  placeholder="Fake Search"
                  className="mr-2"
                />
              </Form.Group>
              <Button>Fake Button</Button>
            </Form>
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  );
};

CustomNavbar.propTypes = {
  siteTitle: PropTypes.string,
  pageInfo: PropTypes.string,
};

CustomNavbar.defaultProps = {
  siteTitle: ``,
  pageInfo: ``,
};

export default CustomNavbar;
